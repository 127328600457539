<template>
  <div>
    <div class="header">
      <div class="header_top">
        <img src="../../assets/user.png" alt />
        <span class="center">用户管理</span>
        <img src="../../assets/right.png" alt />
        <span class="center">企业用户</span>
      </div>

      <div style="display: flex; align-items: center">
        <div style="display: flex; align-items: center">
          <span class="seach_text">关键字：</span>
          <el-input
            placeholder="请输入用户名"
            v-model="keywords"
            @input="query"
            size="mini"
            style="margin-right: 10px; width: 200px"
            prefix-icon="el-icon-search"
            clearable
          ></el-input>
          <span class="seach_text">角色：</span>

          <el-select size="mini" v-model="roles" placeholder="请选择" @change="query">
            <el-option
              :label="item.name"
              :value="item.alias"
              v-for="(item, i) in roleList"
              :key="i"
            ></el-option>
          </el-select>
        </div>
        <el-button
          style="margin-left:16px;"
          size="small"
          type="primary"
          icon="el-icon-plus"
          @click="visible = true"
        >新建</el-button>
      </div>
    </div>
    <el-table :data="userList" style="width: 100%；">
      <el-table-column fixed prop="name" label="用户名 & 账号">
        <template scope="scope">
          <div
            style="
                color: #409eff;
                text-decoration: underline;
                cursor: pointer;
              "
            @click="getMore(scope.row)"
          >
            <span v-if="scope.row.name">{{ scope.row.name }}</span>
            <span v-else>{{ scope.row.code }}</span>
          </div>
          <div>{{ scope.row.username }}</div>
        </template>
      </el-table-column>
      <el-table-column label="电话号码">
        <template scope="scope">{{ scope.row.phone || scope.row.username }}</template>
      </el-table-column>

      <!-- <el-table-column label="角色">
        <template scope="scope">
          {{ scope.row.roles | rolesVal }}
        </template>
      </el-table-column>-->
      <el-table-column label="创建时间">
        <template scope="scope">{{ scope.row.create_time | dateVal }}</template>
      </el-table-column>
      <el-table-column label="操作" width="250">
        <template scope="scope">
          <el-popover placement="top" width="400" trigger="manual" :ref="'popover-' + scope.row.id">
            <div class="del-pop">
              <img src="../../assets/del.png" alt />
            </div>
            <div class="del-title">删除用户</div>
            <div class="del-sumtitle">是否确认删除本用户？</div>
            <div class="del-btn">
              <el-button type="primary" @click="delRow(scope.row.code, scope.row.id)" plain>确认</el-button>
              <el-button type="info" @click="doCancel(scope.row.id)" plain>取消</el-button>
            </div>
            <el-button
              type="text"
              slot="reference"
              @click="pOpen(scope.row.id)"
              v-if="scope.row.type === 'custom'"
            >删除</el-button>
          </el-popover>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <span class="clickColor">共{{ total }}条</span>
      <span class="clickColor" style="margin:0 10px">每页{{ pageSize }}条</span>
      <el-pagination
        background
        :current-page.sync="pageNumber"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-size="pageSize"
        prev-text="上一页"
        next-text="下一页"
        layout="prev,pager, next,jumper"
        :total="total"
      ></el-pagination>
    </div>
    <AddCustomerUser :dialogFormVisible.sync="visible" @close="closeDialog"></AddCustomerUser>
  </div>
</template>

<script>
import { api } from '/src/api/base';
//全部用户列表
export const userList = api()('/user.list.json', 'userList');
export const userDdelCustomerUser = api()('/user.del.customerUser.json');

export default {
  components: {
    AddCustomerUser: () => import('./addCustomerUser.vue')
  },
  data () {
    return {
      keywords: '',
      roles: '',
      roleList: [],
      userList: [],
      pageNumber: 1,
      pageSize: 10,
      total: 0,
      visible: false
    };
  },
  async created () {
    this.roles = this.$route.params.$preload.roleList
      .map(it => it.alias)
      .join(',');
    this.roleList = [
      {
        name: '全部',
        alias: this.roles
      },
      ...this.$route.params.$preload.roleList
    ];
    await this.getUserList();
  },
  methods: {
    async getUserList () {
      const params = {
        keywords: this.keywords,
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        roles: this.roles
      };
      const res = await userList(params);
      this.userList = res.list;
      this.total = res.total;
    },
    handleSizeChange (val) {
      this.pageSize = val;
      this.getUserList();
    },
    handleCurrentChange (val) {
      this.pageNumber = val;
      this.getUserList();
    },
    query () {
      this.pageNumber = 1;
      this.getUserList();
    },
    getMore (row) {
      this.$router.push({
        path: '/system/user/detail',
        query: {
          code: row.code
        }
      });
    },
    async delRow (code, id) {
      await userDdelCustomerUser({ code });
      this.$message.success('用户已删除');
      this.doCancel(id);
      this.getUserList();
    },
    closeDialog () {
      this.pageNumber = 1;
      this.getUserList();
    },
    pOpen (id) {
      this.$refs['popover-' + id].doShow();
    },
    doCancel (id) {

      this.$refs['popover-' + id].doClose();
      Object.keys(window._list).forEach(it => {
        window._list[it].doClose();
      });
    },

  }
};
</script>

<style></style>
